import { request, proRequest } from '@/utils/request.js'
import { transformProduct, transformComment } from '@/utils/transform';

export function getBases() {
    return request.get('user/company/bases')
}

// 获取案例列表
export function getProducts(params) {
    return request.get('/bases', { params }).then(({ data }) => {
        let temp = {}, rows;
        rows = data.rows || [];

        temp.count = data.count;
        temp.rows = rows.map(row => transformProduct(row))
        return Promise.resolve(temp)
    })
}

// 获取当前公司案例列表
export function getCurrentProducts() {
    return request.get('/user/company/bases')
}

// 获取当前公司案例详情
export function getCurrentProductDetail(id) {
    return request.get(`/user/company/bases/${id}`)
}

// 获取公司案例列表
export function getCompanyBases(id) {
    return request.get(`/company/${id}/bases`)
}

// 获取案例详情
export function getProduct(id) {
    return request.get(`/bases/${id}`)
}

// 获取案例详情
export function getProductDraft(id) {
    return request.get(`/bases/${id}/draft`)
}

// 设置案例可见性
export function setProductVisible(id, data) {
    return request.post(`/bases/${id}/visible`, data)
}

// 创建案例
export function createProduct(data) {
    return request.post(`/bases`, data)
}

// 保存案例
export function saveProduct(data) {
    return request.put(`/bases/${data.ID}`, data)
}

// 删除案例
export function deleteProduct(id) {
    return request.delete(`/bases/${id}`)
}

// 提交案例
export function evaluateProduct(id) {
    return request.post(`/bases/${id}/submit`)
}

// 获取后台案例审核列表
export function getProProducts(params) {
    return proRequest.get(`/bases`, { params })
}

// 获取企业案例审核列表
export function getProCompanyProducts(id) {
    return proRequest.get(`/company/${id}/bases`)
}

// 获取后台案例详情
export function getProProductDetail(id) {
    return proRequest.get(`/bases/${id}`)
}

// 保存后台案例
export function saveProProduct(id, data) {
    return proRequest.put(`/bases/${id}`, data)
}

// 审核后台案例
export function evaluteProProduct(id, data) {
    return proRequest.post(`/bases/${id}/audit`, data)
}

// 创建产品提问
export function createProductComment(id, data) {
    return request.post(`/bases/${id}/comments`, data)
}

// 获取产品提问
export function getProductComment(id, params) {
    return request.get(`/bases/${id}/comments`, { params }).then(({ data }) => {
        let temp = {}, rows;
        rows = data.rows || [];

        temp.count = data.count;
        temp.rows = rows.map(row => transformComment(row))
        return Promise.resolve(temp)
    })
}
