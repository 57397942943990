<template>
  <div class="wrapper">
    <div class="text-center">
      <a-input-search
        style="width: 580px"
        size="large"
        enter-button
        v-model="name"
        @search="search"
      ></a-input-search>
    </div>
    <list-filter
      :filters="filters"
      v-model="selected"
      @filter="filter"
    ></list-filter>
    <pagination-list
      type="e-base"
      :gutter="20"
      :size="3"
      :total="total"
      :dataSource="computedDataSource"
      :pagination.sync="pagination"
      @change="jump"
    ></pagination-list>
  </div>
</template>

<script>
import day from "dayjs";
import { upload } from "@/api";
import { getProProducts } from "@/api/base.js";
export default {
  data() {
    return {
      filters: [{ label: "审核状态", optionFlag: "status" }],
      selected: [],
      name: "",
      dataSource: [],
      total: 0,
      pagination: {
        limit: 9,
        offset: 0,
      },
    };
  },
  inject: ["options"],
  created() {
    this.getList();
  },
  computed: {
    computedDataSource() {
      const statusOptions = this.options.status;
      return this.dataSource.map((product) => {
        const detail = product.Detail;
        let logo = "",
          SubmitTime = day(product.SubmittedAt).format("YYYY-MM-DD HH:mm"),
          company = product.Company ? product.Company.Info.Name : "-",
          status = statusOptions[product.AuditStatus - 1].Name,
          name = detail.Name,
          id = product.ID;

        if (detail.Photos) {
          const photo = detail.Photos[0];
          if (photo && photo.ID) {
            logo = upload() + "/" + photo.ID;
          }
        }
        return {
          id,
          name,
          status,
          logo,
          SubmitTime,
          company,
        };
      });
    },
  },
  methods: {
    generateParams() {
      const status = this.selected.find((item) => item.flag === "status");
      const temp = {
        name: this.name,
        status: status && status.id?status.id:null,
      };
      Object.assign(temp, this.pagination);
      return temp;
    },
    getProProducts(temp) {
      getProProducts(temp).then(({ data }) => {
        this.total = data.count;
        this.dataSource = data.rows || [];
      });
    },
    getList() {
      const temp = this.generateParams();
      console.log(temp);
      this.getProProducts(temp);
    },
    search() {
      this.$set(this.pagination, "offset", 0);
      this.getList();
    },
    filter() {
      this.$set(this.pagination, "offset", 0);
      this.getList();
    },
    jump() {
      this.getList();
    },
  },
};
</script>

<style scoped>
.wrapper {
}
</style>